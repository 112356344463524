import { Component, Show, createMemo, createEffect } from "solid-js";
import { Motion, Presence } from "solid-motionone";
import { useItemOrderContext } from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import fractionString from "~/utils/fraction";
import { PT } from "~/utils/products";

export const JoinWarning: Component<{ max?: number; min?: number }> = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();

  let max = props.max || 116;
  let min = props.min || 4;

  // * Very convoluted memos that check whether the join is too big/small.
  // * Stack logic & typescript safety make this very unreadable, need to 
  // * refactor. External function that does the math, returns the final 
  // * inside/outside dimenions that can be checked against min/max here.
  const tooBig = createMemo(() => {
    // ? make sure w/h/moulding width exist
    if (
      !(
        orderData.selected?.width &&
        orderData.selected?.height &&
        orderData?.width
      )
    )
      return false;

    if (orderData.selected.sizing === "I") {
      if (orderData.selected.productType?.includes(PT.STACK)) {
        // Inside sizing, stack:
        // ? if order is stack, make sure inside/outside stacks exist
        if (
          !(
            orderData.selected?.stacks?.outsideDetails?.mouldingWidth &&
            orderData.selected?.stacks?.insideDetails?.mouldingWidth
          )
        )
          return false;

        if (
          orderData.selected.width +
            orderData.selected.stacks.outsideDetails.mouldingWidth * 2 +
            orderData.selected.stacks.insideDetails.mouldingWidth * 2 +
            (orderData.selected.stackCount ==
              3 /* make sure middle stack exists */ &&
            orderData.selected?.stacks?.middleDetails?.mouldingWidth
              ? orderData.selected.stacks.middleDetails.mouldingWidth * 2
              : 0) >
            max ||
          orderData.selected.height +
            orderData.selected.stacks.outsideDetails.mouldingWidth * 2 +
            orderData.selected.stacks.insideDetails.mouldingWidth * 2 +
            (orderData.selected.stackCount ==
              3 /* make sure middle stack exists */ &&
            orderData.selected?.stacks?.middleDetails?.mouldingWidth
              ? orderData.selected.stacks.middleDetails.mouldingWidth * 2
              : 0) >
            max
        ) {
          return true;
        }
      } else if (
        // Inside sizing, non-stack:
        orderData.selected.width + orderData.width * 2 > max ||
        orderData.selected.height + orderData.width * 2 > max
      ) {
        return true;
      }
    } else {
      // Outside sizing, non-stack/stack (stack irrelevant for outside sizing):
      if (orderData.selected.width > max || orderData.selected.height > max) {
        return true;
      }
    }
    return false;
  });
  const tooSmall = createMemo(() => {
    // ? make sure w/h/moulding width exist
    if (
      !(
        orderData.selected?.width &&
        orderData.selected?.height &&
        orderData?.width
      )
    )
      return false;

    if (orderData.selected.sizing === "O") {
      if (orderData.selected.productType?.includes(PT.STACK)) {
        // Outside sizing, stack:
        // ? if order is stack, make sure inside/outside stacks exist
        if (
          !(
            orderData.selected?.stacks?.outsideDetails?.mouldingWidth &&
            orderData.selected?.stacks?.insideDetails?.mouldingWidth
          )
        )
          return false;

        if (
          orderData.selected.width -
            orderData.selected.stacks.outsideDetails.mouldingWidth * 2 -
            orderData.selected.stacks.insideDetails.mouldingWidth * 2 -
            (orderData.selected.stackCount ==
              3 /* make sure middle stack exists */ &&
            orderData.selected?.stacks?.middleDetails?.mouldingWidth
              ? orderData.selected.stacks.middleDetails.mouldingWidth * 2
              : 0) <
            min ||
          orderData.selected.height -
            orderData.selected.stacks.outsideDetails.mouldingWidth * 2 -
            orderData.selected.stacks.insideDetails.mouldingWidth * 2 -
            (orderData.selected.stackCount ==
              3 /* make sure middle stack exists */ &&
            orderData.selected?.stacks?.middleDetails?.mouldingWidth
              ? orderData.selected.stacks.middleDetails.mouldingWidth * 2
              : 0) <
            min
        ) {
          return true;
        }
        // end stack logic
      } else if (
        // Outide sizing, non-stack:
        orderData.selected.width - orderData.width * 2 < min ||
        orderData.selected.height - orderData.width * 2 < min
      ) {
        return true;
      }
    } /* Inside Sizing */ else {
      // Inside sizing, non-stack/stack (stack irrelevant for inside sizing)
      if (orderData.selected.width < min || orderData.selected.height < min) {
        return true;
      }
    }
    return false;
  });

  createEffect(() => {
    if (tooBig() || tooSmall()) {
      setOrderData("selected", "validDimensions", false);
    } else {
      setOrderData("selected", "validDimensions", true);
    }
    // Should this unset validDimensions onCleanup? Test
  });

  return (
    <>
      <Presence exitBeforeEnter>
        <Show when={tooSmall()}>
          <Motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: 20,
              transition: { duration: 0.4 },
            }}
            transition={{ duration: 0.4, easing: "ease-in-out" }}
          >
            <InfoBox type="warning">
              Please ensure a minimum interior width and height of{" "}
              <span class="fraction">{fractionString(min)}</span>
            </InfoBox>
          </Motion.div>
        </Show>
      </Presence>
      <Presence exitBeforeEnter>
        <Show when={tooBig()}>
          <Motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: 20,
              transition: { duration: 0.4 },
            }}
            transition={{ duration: 0.4, easing: "ease-in-out" }}
          >
            <InfoBox type="warning">
              <p>
                Please ensure a maximum exterior width and height of{" "}
                <span class="fraction">{fractionString(max)}</span>
              </p>
            </InfoBox>
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
};
