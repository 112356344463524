import { Component, Show, onMount, onCleanup } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { InfoBox } from "~/components/utility";
import {
  Dimensions,
  Allowance,
  QtyCounter,
  Orientation,
  JoinWarning,
} from "../";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";

export const OversizedJoinWarning = () => {
  return (
    <InfoBox>
      <p>
        This item is considered oversized. Please reach out to customer care for
        crating and shipping charges for this item. Alternatively, you can
        choose a chop with a wedge for reduced shipping rates. If you choose to
        proceed, you agree to the additional charges that may apply for shipping
        this product.
      </p>
    </InfoBox>
  );
};

export const Join: Component = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();

  const FreightChargeConsent = () => {
    onMount(() => {
      setOrderData("selected", "freightChargeConsent", undefined);
    });
    onCleanup(() => {
      setOrderData("selected", "freightChargeConsent", undefined);
    });

    return (
      <div class="border bg-faint-blue p-3 flex items-center text-sm text-roma-dark-grey">
        <button
          aria-label={
            !orderData.selected.freightChargeConsent
              ? "Check freight charge consent box"
              : "Uncheck freight charge consent box"
          }
          class="w-5 aspect-square flex justify-center items-center self-center"
          classList={{
            "bg-white border": !orderData.selected.freightChargeConsent,
            "bg-roma-blue": !!orderData.selected.freightChargeConsent,
          }}
          onClick={() => {
            setOrderData(
              "selected",
              "freightChargeConsent",
              !orderData.selected.freightChargeConsent
            );
          }}
        >
          <Icon
            path={check}
            class="text-white w-4"
            classList={{
              hidden: !orderData.selected.freightChargeConsent,
            }}
          />
        </button>
        <p class="ml-3">
          I acknowledge that my Roma Elite product may incur additional shipping
          costs.
        </p>
      </div>
    );
  };

  return (
    <>
      <Dimensions />
      <JoinWarning />
      <Show when={orderData.selected.oversizeWarning}>
        <OversizedJoinWarning />
      </Show>
      <Show when={orderData.isStretcher}>
        <Orientation />
        Orientation
      </Show>
      <Allowance />
      <QtyCounter />
      <Show when={orderData.currentProduct?.Category === "Roma Elite"}>
        <InfoBox>
          <p>
            Roma Elite products are crated for shipping, and may incur
            additional shipping costs.
          </p>
        </InfoBox>
        <FreightChargeConsent />
      </Show>
    </>
  );
};
