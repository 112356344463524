import {
  Component,
  Show,
  Match,
  Switch,
  useContext,
  onCleanup,
  createEffect,
} from "solid-js";
import { TextFieldInput, SelectBox } from "~/components/inputs";
import { InfoBox } from "~/components/utility";
import { useItemOrderContext } from "~/utils/contexts";
import { LENGTH, BOX } from "~/utils/products";
import { PT } from "~/utils/products";
import { cube } from "solid-heroicons/outline";
import { debounce } from "@solid-primitives/scheduled";
import {
  CUTTING,
  CuttingLabels,
  CuttingOption
} from "~/services/roma-api/cart/types-productOrdering";

// export const cuttingInstrOptions = [
//   { value: "fiveAndBalance", label: "5' and balance" },
//   { value: "sixAndBalance", label: "6' and balance" },
//   { value: "sevenAndBalance", label: "7' and balance" },
//   { value: "eightAndBalance", label: "8' and balance" },
//   { value: "half", label: "Cut in half" },
//   { value: "full", label: "None (Full Length)" },
//   { value: "other", label: "Other" },
// ];

export const cuttingInstrOptions = Object.values(CUTTING).map((value) => ({
  value: value as CuttingOption,
  label: CuttingLabels[value],
}));

// Note: Position of "other" at the end of the array is important for when an item is being edited. If it needs to be changed from the last position, alter the usage of "optionsExcludingOther" in order/index
// Note during refactor - this can probably be resolved if it becomes a problem

export const Length: Component<{
  label?: string;
  minFootage?: number;
}> = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();

  onCleanup(() => {
    setOrderData("selected", "cuttingInstr", undefined);
  });

  const setLinearFt = debounce((val: string | number) => {
    setOrderData("selected", "linearFt", Number(val));
  }, 500);

  let defaultVal = Math.trunc(orderData.selected.linearFt ?? 0).toString();

  if (orderData.selected.productType === "contract" && !orderData.editProduct && props.minFootage) {
    defaultVal = props.minFootage?.toString();
  }

  return (
    <>
      <TextFieldInput
        name="length-linear-ft"
        type="number"
        class="!rounded-sm text-sm text-roma-dark-grey w-1/2"
        label={props.label ?? "Quantity (Linear Feet)"}
        labelClass="text-roma-dark-grey"
        defaultValue={defaultVal}
        onChange={(val) => {
          setLinearFt(Math.trunc(+val));
        }}
        required
        placeholder="Enter an amount in feet"
      />
      <Show when={orderData.selected.productType === PT.LENGTH}>
        <SelectBox
          options={cuttingInstrOptions}
          id="cutting-instruction"
          defaultValue={orderData.selected.cuttingInstr}
          onChange={(option) =>
            setOrderData("selected", "cuttingInstr", option.value as CuttingOption)
          }
          label="Cutting Instructions"
          placeholder="Please select a cutting instruction"
          required
          triggerClass="rounded-sm"
        />

        <Switch>
          <Match when={orderData.selected.cuttingInstr === "full"}>
            <p class="text-sm text-roma-dark-grey">
              <span class="mr-1">&bull;</span>Additional freight charges apply
              for full length shipments
            </p>
          </Match>
          <Match when={orderData.selected.cuttingInstr === "other"}>
            <TextFieldInput
              name="special-cutting-instructions"
              type="textarea"
              autoResize={true}
              label="Cutting Details"
              labelClass="text-roma-dark-grey"
              placeholder="Please describe your special cutting details"
              onChange={(val) =>
                setOrderData("selected", "specialCuttingInstr", val)
              }
              value={orderData.selected.specialCuttingInstr}
              class="!rounded-sm"
            />
          </Match>
        </Switch>
        <Show when={orderData.availableAs?.includes(PT.BOX)}>
          <InfoBox icon={cube}>
            <p class="inline text-roma-dark-grey">
              This moulding qualifies for box pricing.
            </p>
            <button
              aria-label="Switch to box pricing"
              class="ml-1 text-roma-blue inline"
              onClick={() => {
                setOrderData("selected", "productType", PT.BOX);
              }}
            >
              Switch to box pricing
            </button>
          </InfoBox>
        </Show>
      </Show>
    </>
  );
};
