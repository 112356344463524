import { Component } from "solid-js";
import { useItemOrderContext } from "~/utils/contexts";
import { Sizing, ComboSizeSelector } from "./";

export const Dimensions: Component<{
  insideText?: string; // ? needed ?
  outsideText?: string; // ? needed ?
}> = (props) => {
  const { orderData, setOrderData } = useItemOrderContext();

  // const fractionOptions = (x) => {
  //   const array = Object.keys(fractionObjSixteenths);
  //   const array2 = array.map((item) => {
  //     const number = x + Number(item);
  //     return {
  //       value: number,
  //       label: (
  //         <div class="w-16 flex items-center gap-1">
  //           <span class="">{Math.trunc(x).toString()}</span>
  //           <span class="fraction">
  //             {fractionObjSixteenths[item.toString()]}"
  //           </span>
  //         </div>
  //       ),
  //     };
  //   });
  //   return array2;
  // };

  return (
    <div class="">
      <h4 class="text-sm font-bold text-roma-dark-grey">Dimensions</h4>
      <Sizing />
      <div class="text-sm grid grid-cols-2 gap-2 pt-4">
        <ComboSizeSelector
          update={(x: number) => {
            setOrderData("selected", "width", x);
          }}
          label="Width"
          name="width"
          placeholder="Enter inches"
          initialVal={orderData.selected.width ?? undefined}
          tabIndex="0"
        />
        <ComboSizeSelector
          update={(x: number) => {
            setOrderData("selected", "height", x);
          }}
          label="Height"
          name="height"
          placeholder="Enter inches"
          initialVal={orderData.selected.height ?? undefined}
          tabIndex="0"
        />
      </div>
    </div>
  );
};
